import type { HTTPHeaders } from '../types';

import type { JSONValue } from '@onetext/api';

export const getErrorCode = (err : unknown) : string | number | undefined => {
    if (
        typeof err === 'object' &&
        err !== null &&
        'code' in err && (
            typeof err.code === 'string' ||
            typeof err.code === 'number'
        )
    ) {
        return err.code;
    }
};

export const stringifyError = (err : unknown, level = 1) : string => {
    if (err === undefined) {
        return 'undefined';
    }

    if (err === null) {
        return 'null';
    }

    if (level >= 3) {
        return 'stringifyError stack overflow';
    }

    try {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!err) {
            return `<unknown error: ${ Object.prototype.toString.call(err) }>`;
        }

        if (typeof err === 'string') {
            return err;
        }

        if (typeof err === 'object') {
            const stack = (
                typeof err === 'object' &&
                'stack' in err &&
                typeof err.stack === 'string'
            )
                ? err.stack
                : undefined;

            const message = (
                typeof err === 'object' &&
                'message' in err &&
                typeof err.message === 'string'
            )
                ? err.message
                : undefined;

            const code = getErrorCode(err);

            return `${
                message && !stack?.includes(message)
                    ? `${ message }\n`
                    : ''
            }${
                code
                    ? `Error code: ${ code } `
                    : ''
            }${
                stack
                    ? `${ stack }\n`
                    : ''
            }`;
        }

        if (typeof (err as { toString ?: unknown }).toString === 'function') {
            return (err as { toString : () => string }).toString();
        }

        // $FlowFixMe[method-unbinding]
        return Object.prototype.toString.call(err);

    } catch (newErr : unknown) {
        return `Error while stringifying error: ${ stringifyError(newErr, level + 1) }`;
    }
};

type UnhandledErrorOptions<Data extends JSONValue> = {
    originalError ?: unknown,
    message : string,
    code ?: string | number,
    data ?: Data,
    hardError ?: boolean,
};
export class UnhandledError<Data extends JSONValue> extends Error {
    static allowRewrap = true;

    hardError : boolean;
    code ?: string | number;
    data ?: Data;

    constructor(opts : UnhandledErrorOptions<Data>) {
        const {
            originalError,
            message,
            code,
            data,
            hardError = true
        } = opts;

        const finalMessage = `${
            code
                ? `${ code }: `
                : ''
        }${ message }${
            data
                ? `\n\n${ JSON.stringify(message) }`
                : ''
        }${
            originalError
                ? `\n\nOriginal error: ${ stringifyError(originalError) }`
                : ''
        }`;

        super(finalMessage);
        this.name = 'UnhandledError';
        this.code = code;
        this.data = data;
        this.hardError = hardError;
        Object.setPrototypeOf(this, new.target.prototype);
    }

    clone(opts : Partial<UnhandledErrorOptions<Data>>) : UnhandledError<Data> {
        const ErrorConstructor = this.constructor as typeof UnhandledError;

        return new ErrorConstructor({
            originalError: this,
            message:       this.message,
            code:          this.code,
            data:          this.data,
            hardError:     this.hardError,
            ...opts
        });
    }

    soft() : UnhandledError<Data> {
        return this.clone({
            hardError: false
        });
    }
}

type APIResponseErrorOptions<Data extends JSONValue> = UnhandledErrorOptions<Data> & {
    status : number,
    body : string | undefined,
    headers : HTTPHeaders,
};

export class APIResponseError<Data extends JSONValue> extends UnhandledError<Data> {
    static allowRewrap = true;

    status : number;
    headers : HTTPHeaders;
    body : string | undefined;

    constructor({
        status,
        body,
        headers,
        ...opts
    } : APIResponseErrorOptions<Data>) {
        super(opts);
        this.status = status;
        this.headers = headers;
        this.body = body;
        this.name = 'APIResponseError';
        Object.setPrototypeOf(this, new.target.prototype);
    }
}
