import type { CallOneTextAPIResult } from './api';

import { callOneTextAPI } from './api';

import type { PostAssociateAPI, PostCustomerPropertiesAPI } from '@onetext/api';

export const associate = (
    body : PostAssociateAPI['request']
) : Promise<CallOneTextAPIResult<PostAssociateAPI['response']>> => {
    return callOneTextAPI({
        path: 'associate',
        body
    });
};

export const addCustomerProperties = (
    body : PostCustomerPropertiesAPI['request']
) : Promise<CallOneTextAPIResult<PostCustomerPropertiesAPI['response']>> => {
    return callOneTextAPI({
        path: 'customer/properties/set',
        body
    });
};
